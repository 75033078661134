










import { Component, Prop, Vue } from 'nuxt-property-decorator'
// @ts-ignore
import ArrowRightSvg from '~/static/images/icons/arrow.svg?inline'

@Component({
  components: {
    ArrowRightSvg,
  },
})
export default class CtaLink extends Vue {
  @Prop({ type: String, required: true }) type!: string
  @Prop({ type: String, required: true }) title!: string

  get headingSize(): string {
    switch (this.type) {
      case 'primary':
      case 'secondary':
        return 'h4'
      case 'row':
        return 'span'
      case 'list':
        return 'h5'
      default:
        return 'span'
    }
  }
}
