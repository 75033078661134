



































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import Cta from '@UI/components/cta/Cta.vue'
import { CmsctaCollection, Cmscta } from '@Core/@types/skyway'
import { CMSImageTransformer } from '@Marketing/transformers/image'

@Component({
  inheritAttrs: false,
  components: {
    Cta,
  },
})
export default class CtaCollection extends Vue {
  @Prop({ type: Object }) data!: CmsctaCollection

  get hasImage(): boolean {
    return this.data.extra.image
  }

  get image() {
    return this.hasImage ? CMSImageTransformer(this.data.extra.image) : false
  }

  get ctas(): Cmscta[] {
    return (this.data.ctas as Cmscta[]) || []
  }

  get titleWidthClass(): string {
    if (this.data.extra.type !== 'list') {
      if (this.ctas.length === 2) {
        return 'sm:grid-col-12 md:grid-col-10 lg:grid-col-8 lg:grid-col-start-2'
      } else {
        return 'sm:grid-col-12 md:grid-col-10 lg:grid-col-8'
      }
    }

    if (this.data.extra.type === 'list') {
      return this.hasImage
        ? 'sm:grid-col-12 md:grid-col-10 lg:grid-col-8'
        : 'sm:grid-col-12 md:grid-col-10 md:grid-col-start-2 lg:grid-col-8 lg:grid-col-start-3'
    }

    return ''
  }

  get ctaLayoutWidth(): string | null {
    if (
      this.data.extra.type === 'primary' ||
      this.data.extra.type === 'secondary'
    ) {
      return 'sm:grid-col-12'
    }

    if (this.data.extra.type === 'row') {
      if (this.ctas.length === 2) {
        return 'sm:grid-col-12 md:grid-col-12 lg:grid-col-10 lg:grid-col-start-2'
      } else {
        return 'sm:grid-col-12 md:grid-col-12 lg:grid-col-12'
      }
    }

    if (this.data.extra.type === 'list') {
      return this.hasImage ? 'sm:grid-col-12 md:grid-col-6' : 'sm:grid-col-12'
    }

    return null
  }

  get ctaWidthClass(): string | null {
    if (
      this.data.extra.type === 'primary' ||
      this.data.extra.type === 'secondary'
    ) {
      return 'sm:grid-col-12 md:grid-col-10 md:grid-col-start-2 lg:grid-col-8 lg:grid-col-start-3'
    }

    if (this.data.extra.type === 'row') {
      if (this.ctas.length === 2) {
        return 'sm:grid-col-12 md:grid-col-6'
      } else {
        return 'sm:grid-col-12 md:grid-col-6 lg:grid-col-4'
      }
    }

    if (this.data.extra.type === 'list') {
      return this.hasImage
        ? 'sm:grid-col-12'
        : 'sm:grid-col-12 md:grid-col-10 md:grid-col-start-2 lg:grid-col-8 lg:grid-col-start-3'
    }

    return null
  }
}
