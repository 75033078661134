




















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Cmscta, CmsLink } from '@Core/@types/skyway'
import CtaLink from '@UI/components/cta/CtaLink.vue'

@Component({
  components: {
    CtaLink,
  },
})
export default class Cta extends Vue {
  @Prop({ type: Object }) data!: Cmscta
  @Prop({ type: Number }) count?: number

  protected linkURL = this.link.slug || this.link.url
  protected pageURL = this.$config.get('APP_URL')

  get type(): string {
    return (this.data.extra && this.data.extra.type) || 'primary'
  }

  get link(): CmsLink {
    return this.data.link as CmsLink
  }

  get hasTitle(): boolean {
    return !!(this.type === 'row' && this.data.content)
  }

  get hasContent(): boolean {
    return !!(this.type === 'list' && this.data.content)
  }

  get countClass(): string | null {
    if (this.count === 2) {
      return 'cta--double'
    } else if (this.count === 3) {
      return 'cta--triple'
    } else {
      return null
    }
  }

  get linkTo() {
    return this.isHashLink
      ? { path: this.$route.path, hash: this.link.slug }
      : this.link.slug || this.link.url
  }

  get isHashLink() {
    return this.linkURL?.includes('#')
  }

  get isInternal(): boolean {
    return (
      this.isHashLink ||
      (this.linkURL && this.pageURL && this.linkURL?.includes(this.pageURL))
    )
  }
}
