import { CmsImage, CmsImageSize } from '@Core/@types/skyway'

interface SizeKeyValPairs {
  [key: string]: string
}

interface ImageData {
  title: string
  alt: string
  caption: string
  description: string
  mime_type: string
  url: string
  sizes: SizeKeyValPairs
  extra: any
}

/**
 * Convert inconsistent image data to output a CmsImage type
 * use when marketing content image data is not in the expected
 * format
 *
 * @param { ImageData } input - image data that has incorrect sizes
 *
 * @return { CmsImage }
 */
export function CMSImageTransformer(input: ImageData): CmsImage {
  if (input) {
    const component: CmsImage = Object.assign(
      {},
      {
        title: input.title,
        alt: input.alt,
        caption: input.caption,
        description: input.description,
        mime_type: input.mime_type,
        url: input.url,
        sizes: [],
        extra: input.extra ? input.extra : null,
      }
    )

    for (const [key, value] of Object.entries(input.sizes)) {
      component.sizes &&
        component.sizes.push(CMSImageSizeTransformer(key, value))
    }

    return component
  } else {
    return {
      title: '',
      alt: '',
      caption: '',
      description: '',
      mime_type: '',
      url: '',
      sizes: [],
      extra: null,
    }
  }
}

/**
 * Converts key/val pair of image sizes to expected output object
 *
 * @param { string } name - the size name
 * @param { string } input - the input url
 *
 * @return { CmsImageSize }
 */
export function CMSImageSizeTransformer(name, input): CmsImageSize {
  const size = Object.assign(
    {},
    {
      name: name,
      url: input.url,
      height: input.height,
      width: input.width,
    }
  )
  return size
}

/**
 * Convert inconsistent image data to output a CmsImage type
 * use to deal with image data returned from a Skyway entity
 * event/instance/series etc.
 *
 * @param { ImageData } input - image data that has incorrect sizes
 *
 * @return { CmsImage }
 */
export function CMSEventImageTransformer(input) {
  const component: CmsImage = Object.assign(
    {},
    {
      title: null,
      alt: null,
      caption: null,
      description: null,
      mime_type: null,
      url: input.original,
      sizes: [],
      extra: input.extra ? input.extra : null,
    }
  )

  for (const [key, value] of Object.entries(input)) {
    ;(component.sizes as CmsImageSize[]).push(
      CMSEventImageSizeTransformer(key, value)
    )
  }

  return component
}

export function CMSEventImageSizeTransformer(name, url): CmsImageSize {
  const size = Object.assign(
    {},
    {
      name: name,
      url: url,
      height: null,
      width: null,
    }
  )
  return size
}
